<template>
  <div>
    <ScannedListLayout
      :title="$t('packetsInventory.title')"
      :onClose="openConfirmOverlay"
      :finishText="$t('buttonActions.finish-and-confirm')"
    >
      <ScannedList
        :isEmpty="emptyItems"
        :emptyText="$t('packetsScanned.no-packets')"
        :counts="mappedCounts"
        :tabs="itemTabs"
      >
        <ScannedListInventoryItems
          v-if="tabSelected(TABS.ALL)"
          :items="items"
        />
        <ScannedListInventoryItems
          v-if="tabSelected(TABS.ERRORS)"
          :items="failedItems"
        />
      </ScannedList>

      <template v-slot:footer-buttons>
        <a
          class="btn btn-primary btn-white"
          v-if="hasFailedScans"
          @click="printFailedPacketsLabel"
          >{{ $t("prints.scanned.inventory.print") }}
        </a>
        <a
          class="btn btn-primary btn-white"
          v-if="hasFailedScans"
          @click="rescanFailedPackets"
          >{{ $t("packetsInventory.rescan") }}
        </a>
      </template>
    </ScannedListLayout>

    <OverlayAccept
      v-if="showAcceptOverlay"
      :heading="$t('packetsInventory.success')"
    />
    <OverlayConfirm
      v-if="showConfirmOverlay"
      :title="$t('packetsInventory.confirm.title')"
      :subtitle="$t('packetsInventory.confirm.subtitle')"
      @confirm="finishInventory"
      @cancel="showConfirmOverlay = false"
      :confirmText="$t('buttonActions.yes')"
      :cancelText="$t('buttonActions.no')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { delay } from "lodash";
import { getParams } from "@/mixins/platform-params.js";
import { MOBILE_TYPES } from "@/util/mobile.type";
import { SCANNED_COUNTS_TYPES, getScannedCounts } from "@/util/scanned-counts";
import OverlayAccept from "@/components/OverlayAccept";
import OverlayConfirm from "@/components/Overlay/Confirm";
import ScannedList from "@/components/ScannedList/List";
import ScannedListLayout from "@/components/ScannedList/Layout";
import ScannedListInventoryItems from "@/components/ScannedList/InventoryItems";

const TABS = {
  ALL: "all",
  ERRORS: "errors"
};

export default {
  name: "PacketsInventoryProcess",
  mixins: [getParams],
  components: {
    OverlayAccept,
    OverlayConfirm,
    ScannedList,
    ScannedListLayout,
    ScannedListInventoryItems
  },
  data() {
    return {
      showAcceptOverlay: false,
      showConfirmOverlay: false,
      inventoryId: this.$route.params.inventoryId,
      currentTab: TABS.ALL,
      TABS
    };
  },

  async mounted() {
    await this.getInventoryPackets(this.inventoryId);

    if (this.emptyItems) return;

    if (this.hasFailedScans) {
      this.currentTab = TABS.ERRORS;

      return;
    }

    this.flashAcceptOverlay();
  },

  computed: {
    ...mapState("scanned", ["items", "counts"]),
    ...mapGetters("scanned", ["emptyItems", "hasFailedScans"]),

    mappedCounts() {
      return getScannedCounts(SCANNED_COUNTS_TYPES.INVENTORY, this.counts);
    },

    failedItems() {
      return this.items.filter(item => !item.success);
    },

    itemTabs() {
      if (!this.hasFailedScans) return null;

      return {
        values: Object.values(TABS),
        currentTab: this.currentTab,
        onTabSelect: this.selectTab
      };
    }
  },

  methods: {
    ...mapActions("scanned", [
      "getInventoryPackets",
      "confirmInventory",
      "reprintInventoryLabel"
    ]),

    flashAcceptOverlay() {
      this.showAcceptOverlay = true;

      delay(() => {
        this.showAcceptOverlay = false;
      }, 1000);
    },

    selectTab(name) {
      this.currentTab = name;
    },

    tabSelected(name) {
      return this.currentTab === name;
    },

    async printFailedPacketsLabel({ target }) {
      const response = await this.reprintInventoryLabel(this.inventoryId);
      if (!response) return;

      target.innerText = this.$t("prints.scanned.inventory.reprint");
    },

    rescanFailedPackets() {
      if (!this.isMobile) return;

      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA.backToScan(false);
      } else if (this.platform == MOBILE_TYPES.IOS) {
        window.webkit.messageHandlers.backToScan.postMessage({});
      }
    },

    openConfirmOverlay() {
      this.showConfirmOverlay = true;
    },

    async finishInventory() {
      await this.confirmInventory(this.inventoryId);

      if (!this.isMobile) {
        this.$router.replace({
          name: "dashboard",
          query: {
            platform: this.platform,
            device: this.device
          }
        });

        return;
      }

      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA.backToScan(true);
      } else if (this.platform == MOBILE_TYPES.IOS) {
        window.webkit.messageHandlers.backToScan.postMessage({});
      }
    }
  }
};
</script>
