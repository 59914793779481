<template>
  <div class="overlay-confirm">
    <div class="backdrop" @click="handleCancel" />

    <div class="content">
      <h5>{{ title }}</h5>
      <p v-if="subtitle">{{ subtitle }}</p>

      <div class="d-flex flex-col gap-2">
        <a
          href="#"
          class="btn btn-primary btn-green"
          @click.prevent="handleConfirm"
        >
          {{ confirmText || $t("buttonActions.confirm") }}
        </a>
        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="handleCancel"
        >
          {{ cancelText || $t("buttonActions.cancel") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverlayConfirm",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    confirmText: {
      type: String
    },
    cancelText: {
      type: String
    }
  },

  methods: {
    handleConfirm() {
      this.$emit("confirm");
    },

    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-confirm {
  position: fixed;
  inset: 0;
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .content {
    z-index: 10;
    padding: 1rem;
    background: $white;
    border-radius: 0.5em;
    box-shadow: 0 0 1.75em -0.25em rgba(0, 0, 0, 0.5);

    h5 {
      margin: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
      text-align: center;
    }

    p {
      margin: 1rem;
      margin-bottom: 2rem;
      color: $light-grey;
      text-align: center;
    }
  }
}
</style>
